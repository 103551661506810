import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { StaticImage } from "gatsby-plugin-image";
import TwitchBanner from "../images/notserk_twitchbanner.png";
import NOTSERKHeaderLogo from "../images/notserk_logo-header.png"

const Header = ({ siteTitle }) => {
  const [show, setShow] = React.useState(false);

  const toggleButton = () => {
    if(show)
      setShow(false)
    else
      setShow(true)
  }
  return (
      <header className="container-fluid">
        <div className="row header-bar">
          <div className="name col-sm-3">
            <Link to="/"><StaticImage src="../images/notserk_logo-header.png" alt="Notserk logo"/> </Link>
          </div>
          <div className="menu col-sm-6">
            <nav className="navbar">
              <ul className="navbar-nav flex-row">
                <li className="nav-item">
                  <Link className="nav-link" href="/#about-me-cont/"></Link>
                </li>
                {/*<li className="nav-item">*/}
                {/*  <a className="nav-link" href="https://kesconsulting.tech/">About</a>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*  <Link className="nav-link" to="/gaming-work/">Gaming Work</Link>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*  <Link className="nav-link" to="/#hobbies-section/">Hobbies</Link>*/}
                {/*</li>*/}
              </ul>
            </nav>
          </div>
          <div className="social-links-header col-sm-3">
            <a href="https://www.twitch.tv/notserk"><i className="fab fa-twitch"></i></a>
            <a href="https://www.youtube.com/channel/UCHQnuYhOuAGEYPI6vMIce_A"><i className="fab fa-youtube-square"></i></a>
            <a href="https://twitter.com/notserk"><i className="fab fa-twitter-square"></i></a>
            <a href="https://www.facebook.com/notserk"><i className="fab fa-facebook-square"></i></a>
          </div>
        </div>
        {/*<div className="mobile-menu-group row">*/}
        {/*  <div id="mobile-menu" className="col-sm-12 d-flex justify-content-center">*/}
        {/*    <a className="navbar-brand" href="/">NOTSERK</a>*/}
        {/*    /!*<Button variant="secondary" onClick={toggleButton}><i className="fa fa-bars fa-1x"></i></Button>*!/*/}
        {/*  </div>*/}
        {/*  /!*<div id="mobile-menu-collapse" className="col-sm-12">*!/*/}
        {/*  /!*  <nav className="navbar col">*!/*/}
        {/*  /!*    <div className= {(show ? "" : "collapse")}>*!/*/}
        {/*  /!*      <ul className="navbar-nav flex-column">*!/*/}
        {/*  /!*        <li className="nav-item">*!/*/}
        {/*  /!*          <Link className="nav-link" href="/#about-me-cont">About</Link>*!/*/}
        {/*  /!*        </li>*!/*/}
        {/*  /!*        <li className="nav-item">*!/*/}
        {/*  /!*          <a className="nav-link" href="https://kesconsulting.tech/">Developer</a>*!/*/}
        {/*  /!*        </li>*!/*/}
        {/*  /!*        <li className="nav-item">*!/*/}
        {/*  /!*          <Link className="nav-link" to="/gaming-work">Gaming Work</Link>*!/*/}
        {/*  /!*        </li>*!/*/}
        {/*  /!*        <li className="nav-item">*!/*/}
        {/*  /!*          <Link className="nav-link" to="/#hobbies-section">Hobbies</Link>*!/*/}
        {/*  /!*        </li>*!/*/}
        {/*  /!*      </ul>*!/*/}
        {/*  /!*    </div>*!/*/}
        {/*  /!*  </nav>*!/*/}
        {/*  /!*</div>*!/*/}
        {/*</div>*/}
      </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
